import { useState, memo, useCallback } from 'react'
import { Currency, Pair, Token, CurrencyAmount } from '@echodex/sdk'
import {
    Button,
    Text,
    useModal,
    Flex,
    Box,
    Loading,
    Skeleton,
    Swap as SwapUI,
    ArrowDropDownIcon,
    CopyButton,
} from '@echodex/uikit'
import styled, { css } from 'styled-components'
import { useTranslation } from '@echodex/localization'
import { formatAmount } from '@echodex/utils/formatFractions'

import { FiatLogo } from 'components/Logo/CurrencyLogo'
import AddToWalletButton from 'components/AddToWallet/AddToWalletButton'
import { useAccount } from 'wagmi'
import { isAddress } from 'utils'
import { WrappedTokenInfo } from '@echodex/token-lists'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

type ZapStyle = 'noZap' | 'zap'

interface CurrencyInputPanelProps {
    value: string
    onUserInput: (value: string) => void
    onInputBlur?: () => void
    onPercentInput?: (percent: number) => void
    onMax?: () => void
    showQuickInputButton?: boolean
    showMaxButton: boolean
    maxAmount?: CurrencyAmount<Currency>
    lpPercent?: string
    label?: string
    onCurrencySelect?: (currency: Currency) => void
    currency?: Currency | null
    disableCurrencySelect?: boolean
    hideBalance?: boolean
    pair?: Pair | null
    otherCurrency?: Currency | null
    id: string
    showCommonBases?: boolean
    commonBasesType?: string
    showSearchInput?: boolean
    zapStyle?: ZapStyle
    beforeButton?: React.ReactNode
    disabled?: boolean
    error?: boolean | string
    showUSDPrice?: boolean
    tokensToShow?: Token[]
    currencyLoading?: boolean
    inputLoading?: boolean
    title?: React.ReactNode
    hideBalanceComp?: boolean
}
const CurrencyInputPanel = memo(function CurrencyInputPanel({
    value,
    onUserInput,
    onInputBlur,
    onMax,
    onCurrencySelect,
    currency,
    disableCurrencySelect = false,
    hideBalance = false,
    zapStyle,
    beforeButton,
    pair = null, // used for double token logo
    otherCurrency,
    id,
    showCommonBases,
    commonBasesType,
    showSearchInput,
    disabled,
    error,
    showUSDPrice,
    tokensToShow,
    currencyLoading,
    inputLoading,
    title,
    hideBalanceComp,
}: CurrencyInputPanelProps) {
    const { address: account } = useAccount()

    const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
    const { t } = useTranslation()
    const token = pair ? pair.liquidityToken : currency?.isToken ? currency : null
    const tokenAddress = token && isAddress(token.address) ? (isAddress(token.address) as string) : null

    const [onPresentCurrencyModal] = useModal(
        <CurrencySearchModal
            onCurrencySelect={onCurrencySelect}
            selectedCurrency={currency}
            otherSelectedCurrency={otherCurrency}
            showCommonBases={showCommonBases}
            commonBasesType={commonBasesType}
            showSearchInput={showSearchInput}
            tokensToShow={tokensToShow}
        />,
    )

    // const percentAmount = useMemo(
    //     () => ({
    // 25: maxAmount ? maxAmount.multiply(new Percent(25, 100)).toExact() : undefined,
    //         50: maxAmount ? maxAmount.multiply(new Percent(50, 100)).toExact() : undefined,
    //         75: maxAmount ? maxAmount.multiply(new Percent(75, 100)).toExact() : undefined,
    //     }),
    //     [maxAmount],
    // )

    const handleUserInput = useCallback(
        (val: string) => {
            onUserInput(val)
            setCurrentClickedPercent('')
        },
        [onUserInput],
    )

    const onCurrencySelectClick = useCallback(() => {
        if (!disableCurrencySelect) {
            onPresentCurrencyModal()
        }
    }, [onPresentCurrencyModal, disableCurrencySelect])

    const [, setCurrentClickedPercent] = useState('')

    // const isAtPercentMax = (maxAmount && value === maxAmount.toExact()) || (lpPercent && lpPercent === '100')

    const balance = !hideBalance && !!currency && formatAmount(selectedCurrencyBalance, 6)
    return (
        <SwapUI.CurrencyInputPanel
            id={id}
            disabled={disabled}
            error={error as boolean}
            zapStyle={zapStyle}
            value={value}
            onInputBlur={onInputBlur}
            onUserInput={handleUserInput}
            loading={inputLoading}
            top={
                <>
                    {title}
                    {account && !hideBalanceComp && (
                        <>
                            <Text
                                onClick={!disabled && onMax}
                                color="textSubtle"
                                fontSize="12px"
                                ellipsis
                                title={
                                    !hideBalance && !!currency
                                        ? t('Balance: %balance%', { balance: balance ?? t('Loading') })
                                        : ' -'
                                }
                                style={{ display: 'inline', cursor: 'pointer' }}
                            >
                                {!hideBalance && !!currency
                                    ? balance?.replace('.', '')?.length > 12
                                        ? balance
                                        : t('Balance: %balance%', { balance: balance ?? t('Loading') })
                                    : ' -'}
                            </Text>
                        </>
                    )}
                    {token && tokenAddress && (
                        <Flex style={{ gap: '4px' }} ml="4px" alignItems="center">
                            <CopyButton
                                width="16px"
                                buttonColor="textSubtle"
                                text={tokenAddress}
                                tooltipMessage={t('Token address copied')}
                            />
                            <AddToWalletButton
                                variant="text"
                                p="0"
                                height="auto"
                                width="fit-content"
                                tokenAddress={tokenAddress}
                                tokenSymbol={token?.symbol}
                                tokenDecimals={token.decimals}
                                tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
                            />
                        </Flex>
                    )}
                </>
            }
            bottom={
                <>
                    <Flex alignItems="center" style={{ gap: '12px' }}>
                        {beforeButton}
                        <CurrencySelectButton
                            zapStyle={zapStyle}
                            className="open-currency-select-button"
                            selected={!!currency}
                            onClick={onCurrencySelectClick}
                        >
                            <Flex alignItems="center" justifyContent="space-between">
                                {pair ? (
                                    <DoubleCurrencyLogo
                                        currency0={pair.token0}
                                        currency1={pair.token1}
                                        size={16}
                                        margin
                                    />
                                ) : currency ? (
                                    id === 'onramp-input' ? (
                                        <FiatLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
                                    ) : (
                                        <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
                                    )
                                ) : currencyLoading ? (
                                    <Skeleton width="24px" height="24px" variant="circle" />
                                ) : null}
                                {currencyLoading ? null : pair ? (
                                    <Text id="pair" bold fontSize="20px">
                                        {pair?.token0.symbol}:{pair?.token1.symbol}
                                    </Text>
                                ) : (
                                    <Text id="pair" bold fontSize="20px">
                                        {(currency && currency.symbol && currency.symbol.length > 10
                                            ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                                                  currency.symbol.length - 5,
                                                  currency.symbol.length,
                                              )}`
                                            : currency?.symbol) || t('Select a currency')}
                                    </Text>
                                )}
                                {!currencyLoading && !disableCurrencySelect && <ArrowDropDownIcon />}
                            </Flex>
                        </CurrencySelectButton>
                        {!!showUSDPrice && (
                            <Flex justifyContent="flex-end" mr="1rem">
                                <Flex maxWidth="200px">
                                    {inputLoading ? <Loading width="20px" height="20px" /> : <Box height="18px" />}
                                </Flex>
                            </Flex>
                        )}
                    </Flex>

                    {/* <InputRow selected={disableCurrencySelect}>
                        {account &&
                            currency &&
                            selectedCurrencyBalance?.greaterThan(0) &&
                            !disabled &&
                            label !== 'To' && (
                                <Flex alignItems="right" justifyContent="right">
                                    {maxAmount?.greaterThan(0) &&
                                        showQuickInputButton &&
                                        onPercentInput &&
                                        [25, 50, 75].map((percent) => {
                                            const isAtClickedPercent = currentClickedPercent === percent.toString()
                                            const isAtCurrentPercent =
                                                (maxAmount && value !== '0' && value === percentAmount[percent]) ||
                                                (lpPercent && lpPercent === percent.toString())

                                            return (
                                                <Button
                                                    key={`btn_quickCurrency${percent}`}
                                                    onClick={() => {
                                                        onPercentInput(percent)
                                                        setCurrentClickedPercent(percent.toString())
                                                    }}
                                                    scale="xs"
                                                    mr="5px"
                                                    variant={
                                                        isAtClickedPercent || isAtCurrentPercent
                                                            ? 'primary'
                                                            : 'secondary'
                                                    }
                                                    style={{ textTransform: 'uppercase' }}
                                                >
                                                    {percent}%
                                                </Button>
                                            )
                                        })}
                                    {maxAmount?.greaterThan(0) && showMaxButton && (
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                onMax?.()
                                                setCurrentClickedPercent('MAX')
                                            }}
                                            scale="xs"
                                            variant={isAtPercentMax ? 'primary' : 'secondary'}
                                            style={{ textTransform: 'uppercase' }}
                                        >
                                            {t('Max')}
                                        </Button>
                                    )}
                                </Flex>
                            )}
                    </InputRow> */}
                </>
            }
        />
    )
})

const CurrencySelectButton = styled(Button).attrs({ variant: 'text' })<{ zapStyle?: ZapStyle }>`
    padding: 0px;
    background-color: ${({ theme }) => theme.colors.backgroundAlt};
    border: 1px solid ${({ theme }) => theme.colors.borderCard};
    border-radius: 6px;
    padding: 6px 8px;
    ${({ zapStyle, theme }) =>
        zapStyle &&
        css`
            background: ${theme.colors.background};
            border: 1px solid ${theme.colors.cardBorder};
            height: auto;
        `};
`
export default CurrencyInputPanel
